import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import SocialLinks from '../components/sociallinks/SocialLinks'

import LottieImage from '../components/lottieimage/LottieImage'



const LoadingScreen = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.getElementById("google_translate_element").hidden = true;
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])

    return (
        <>

            {
                loading ?
                    <LottieImage />
                    :
                    <Redirect to='/home' />
            }

        </>
    )
}

export default LoadingScreen
