import React from 'react'
import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import BuyImage from '../assets/how-to-buy.jpg'
import Footer from '../components/footer/Footer'
import Buy from '../components/buy/Buy'
import ScrollTop from '../components/scrolltop/ScrollTop'

const HowtoBuy = () => {
    return (
        <>
            <SocialLinks />
            <NavBar />
            <div className="container">
                <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                        <div className="buy-container">
                            {/* <img src={BuyImage} alt="" className="img-responsive" /> */}
                            <Buy />
                        </div>
                    </div>
                </div>
            </div>
            <ScrollTop />
            <Footer />
        </>
    )
}

export default HowtoBuy
