import React from 'react'
import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'
import MarketingRd from '../components/marketingrd/MarketingRd'

const Marketing = () => {
    return (
        <>
            <SocialLinks />
            <NavBar />
            <MarketingRd />
            <ScrollTop />
            <Footer />
        </>
    )
}

export default Marketing
