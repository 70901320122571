import React, { useEffect } from 'react'
import Logo from '../../assets/logo.png'

const NavBar = () => {

    useEffect(() => {
        document.getElementById("google_translate_element").hidden = false;
    }, [])

    return (
        <div className="nav-bar-container">

            <div className="main-nav-bar">
                <div className="container">
                    <div className="nav-container">
                        <div className="logo-container">
                            <a href="/"><img src={Logo} alt="Logo" /></a>
                        </div>
                        <div className="nav-bar-items">
                            {/* <GoogleDiv /> */}
                            <ul>
                                <li><a href="/howtobuy">How To Buy</a></li>
                                <li><a href="/home/#roadmap">Roadmap</a></li>
                                <li><a href="/documents-audits">Documents & Audits</a></li>
                                <li><a href="/home">Shop</a></li>
                                <li><a href="/faq">FAQ</a></li>
                                <li><a href="/home/#contactus">Contact</a></li>
                                <li><a target="_blank" href="https://poocoin.app/tokens/0x4e34fcfc6a5aa091990b58f88490e67f25471b72">Charts</a></li>
                                <li><a href="/team">Team</a></li>
                                <li><a href="/media">Media</a></li>
                            </ul>
                        </div>


                        <div className="mobile-nav">
                            <nav class="navbar navbar-inverse">
                                <div class="navbar-header">
                                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>
                                    <a class="navbar-brand" href="/"><img src={Logo} alt="Logo" /></a>
                                </div>
                                <div class="collapse navbar-collapse" id="myNavbar">
                                    <ul class="nav navbar-nav">
                                        <li><a href="/howtobuy">How To Buy</a></li>
                                        <li><a href="/home/#roadmap">Roadmap</a></li>
                                        <li><a href="/documents-audits">Documents & Audits</a></li>
                                        <li><a href="/home">Shop</a></li>
                                        <li><a href="/faq">FAQ</a></li>
                                        <li><a href="/home/#contactus">Contact</a></li>
                                        <li><a target="_blank" href="https://poocoin.app/tokens/0x4e34fcfc6a5aa091990b58f88490e67f25471b72">Charts</a></li>
                                        <li><a href="/team">Team</a></li>
                                        <li><a href="/media">Media</a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div >

        </div>
    )
}

export default NavBar
