import React from 'react'
import Coin from '../../assets/icons/coin.svg'
import Dex from '../../assets/icons/dex.svg'
import Geko from '../../assets/icons/geko.svg'

const SocialLinks = () => {
    return (
        <div className="social-links">
            <div className="container-test">
                <a target="_blank" href="https://www.facebook.com/pizzabuckscoin" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-facebook-f"></i>
                    </span>
                </a>
                <a target="_blank" href="https://www.instagram.com/pizzabucksofficial/" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-instagram"></i>
                    </span>
                </a>
                <a target="_blank" href="https://twitter.com/pizza_bucks" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-twitter"></i>
                    </span>
                </a>
                <a target="_blank" href="https://t.me/pizzabucks" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-telegram-plane"></i>
                    </span>
                </a>
                <a target="_blank" href="https://discord.com/invite/rBhxDscqnZ" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-discord"></i>
                    </span>
                </a>
                <a target="_blank" href="https://www.tiktok.com/@pizzabucksofficial" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-tiktok"></i>
                    </span>
                </a>

                <a target="_blank" href="https://www.youtube.com/channel/UCzMCUnEpRVTRGBiUGO8marw" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-youtube"></i>
                    </span>
                </a>

                <a target="_blank" href="https://www.reddit.com/r/pizzabucksofficial/" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-reddit-alien"></i>
                    </span>
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/79810809/admin" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-linkedin-in"></i>
                    </span>
                </a>
                <a target="_blank" href="https://poocoin.app/tokens/0x4e34fcfc6a5aa091990b58f88490e67f25471b72" className="social-icon">
                    <span className="social-cell">
                        <i class="fas fa-poo"></i>
                    </span>
                </a>
                {/* <a href="#" className="social-icon">
                    <span className="social-cell">
                        <i class="fas fa-poo"></i>
                    </span>
                </a> */}
                <a target="_blank" href="https://bscscan.com/token/0x4e34fcfc6a5aa091990b58f88490e67f25471b72" className="social-icon">
                    <span className="social-cell">
                        <i class="fas fa-chart-line"></i>
                    </span>
                </a>

                <a target="_blank" href="https://dex.guru/token/0x4e34fcfc6a5aa091990b58f88490e67f25471b72-bsc" className="social-icon">
                    <span className="social-cell">
                        <img src={Dex} alt="" />
                    </span>
                </a>
                {/* <a href="#" className="social-icon">
                    <span className="social-cell">
                        <img src={Dex} alt="" />
                    </span>
                </a> */}
                {/* <a href="#" className="social-icon">
                    <span className="social-cell">
                        <img src={Coin} alt="" />
                    </span>
                </a> */}
                {/* <a href="#" className="social-icon">
                    <span className="social-cell">
                        <img src={Coin} alt="" />
                    </span>
                </a> */}
                <a target="_blank" href="https://coinmarketcap.com/currencies/pizzabucks/" className="social-icon">
                    <span className="social-cell">
                        <img src={Coin} alt="" />
                    </span>
                </a>
                {/* <a href="#" className="social-icon">
                    <span className="social-cell">
                        <img src={Geko} alt="" />
                    </span>
                </a> */}
                <a target="_blank" href="https://www.coingecko.com/en/coins/pizzabucks" className="social-icon">
                    <span className="social-cell">
                        <img src={Geko} alt="" />
                    </span>
                </a>

            </div>
        </div>
    )
}

export default SocialLinks
