import React, { useState } from 'react'

import Logo01 from '../../assets/newlogo/logo01.png'

const Games = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0x4e34fcfc6a5aa091990b58f88490e67f25471b72')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <div className="starparalax">
            <div id="title" className="buy-now-class">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="buy-instructions games-soon"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <h3>Coming Soon!</h3>
                                <h4>Careers with PizzaBucks</h4>
                                <p>Please contact us if you are: <br />
                                    Game developers and designers. <br />
                                    NFT designers <br />
                                    Email: info@safe-pizza.com <br />
                                    SEND your resume.</p>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Games
