import React, { useEffect, useState } from 'react'

import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import MainImages from '../components/mainimages/MainImages'
import StarParalax from '../components/starparalax/StarParalax'
import TimeLine from '../components/timeline/TimeLine'
import ContactUs from '../components/contactus/ContactUs'
import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'
import MailChimp from '../components/mailchimp/MailChimp'

import Devider from '../assets/path-1.png'
import DeviderSecond from '../assets/path-2.png'


const Home = () => {
    return (
        <>


            <NavBar />
            <SocialLinks />
            <StarParalax />
            <div className="divider top">
                <img src={Devider} alt="" />
            </div>
            <MainImages />
            {/* <div className="divider">
                <img src={DeviderSecond} alt="" />
            </div> */}
            <TimeLine />
            <div className="divider">
                <img src={Devider} alt="" />
            </div>
            {/* <MailChimp /> */}
            <ContactUs />
            <Footer />
            <ScrollTop />

        </>
    )
}

export default Home
