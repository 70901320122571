import React, { useState } from 'react'
import AstrNoid from '../../assets/ast/ast-01.png'
import Charity from '../../assets/charity-fund.png'
import Marketing from '../../assets/marketing.png'
import LottieImage from '../lottieimage/LottieImage'
import Logo01 from '../../assets/newlogo/logo01.png'
import Logo02 from '../../assets/newlogo/logo02.png'
import Games from '../../assets/games.svg'

const StarParalax = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0x4e34fcfc6a5aa091990b58f88490e67f25471b72')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <div className="starparalax">
            <div className="starcontainer">
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
            </div>
            <div id="title">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="main-logo-cont">
                                <div className="button-container howtobuy"
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                    data-aos-easing="ease-in-out-cubic"
                                >
                                    <div className="button-logo">
                                        <img src={Logo01} alt="" />
                                    </div>
                                    <div className="mission-text-container">
                                        {/* <h1>
                                            <span className="mission-text">MISSION</span>
                                            <br />
                                            <img src={Logo02} alt="" /> FOOD BANK
                                        </h1> */}
                                    </div>
                                    <h2>Holding  $PIZZAB = Passive Income</h2>
                                    {/* <a target="_blank" href="#">Buy on PancakeSwap V2</a> */}
                                    <a target="_blank" href="https://pancakeswap.finance/swap?outputCurrency=0x4E34FCFC6a5AA091990b58F88490E67f25471B72">Buy on PancakeSwap V2</a>
                                    <a target="_blank" href="https://www.tracker.pizza-bucks.com/">$ PIZZAB Tracker</a>
                                    <h2>#DEFI NFT GAMES</h2>
                                </div>
                            </div>
                            <div className="token-container"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <div className="games-div">
                                    <h3>TOKEN INFO</h3>
                                    <p>The Official PizzaBucks  Contract</p>
                                    <div className="copy-buttons">
                                        <h4>0x4e34fcfc6a5aa091990b58f88490e67f25471b72</h4>
                                        <span>{alertmessage}</span> <button onClick={() => { copydata(); allertdata(); }} ><i class="far fa-copy"></i> COPY</button>
                                        <a target="_blank" href="https://bscscan.com/token/0x4e34fcfc6a5aa091990b58f88490e67f25471b72"><button>GO <i class="fas fa-chevron-right"></i></button></a>
                                    </div>
                                </div>
                                <div className="games-div game-image">
                                    <a href="/games"><img src={Games} alt="" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div id="animated-example" class="animated bounce">
                                <img src={AstrNoid} alt="" className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StarParalax
