import React from 'react'
import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import BuyImage from '../assets/how-to-buy.jpg'
import Footer from '../components/footer/Footer'
import FaqItems from '../components/faqitem/FaqItems'
import ScrollTop from '../components/scrolltop/ScrollTop'

const FaqScreen = () => {
    return (
        <>
            <SocialLinks />
            <NavBar />
            <div className="container">
                <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                        <div className="buy-container">
                            <FaqItems />
                        </div>
                    </div>
                </div>
            </div>
            <ScrollTop />
            <Footer />
        </>
    )
}

export default FaqScreen
