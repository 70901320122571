import React from 'react'
import AOS from "aos";

// import DonutChartMain from '../donutchart/DonutChart';
import "aos/dist/aos.css";

AOS.init();

const MainImages = () => {
    return (
        <div className="main-images-div">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-images">
                            <h2>TOKENOMICS</h2>
                            <div
                                data-aos="fade-up"
                                data-aos-delay="50"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                {/* <DonutChartMain /> */}

                                <div className="chart-items">
                                    <div className="chart-item box-01">
                                        <h3>BURN</h3>
                                        <ul>
                                            <li>PIZZABUCKS will periodically Manually burn its supply on a monthly basis, as needed.</li>
                                            <li>1% of every transaction (buy, sell or transfer) will be set aside for burn or buybacks for PIZZABUCKS AND SAFEPIZZA</li>
                                            <li>PIZZABUCKS will weekly buy SafePizza to send to Burn Address to keep reducing the supply of SafePizza. Each buy of SafePizza will provide reflection for SafePizza Holders and Auto LP’s, while the process is sent to Burn Address.</li>
                                        </ul>
                                    </div>

                                    <div className="chart-item box-02">
                                        <h3>LIQUIDITY</h3>
                                        <ul>
                                            <li>3% Auto Liquidity is set up for PIZZABUCKS. Each Transaction of Buy/Sell/Trade will be sent to Liquidity, to ensure that the Liquidity is kept in a healthy ratio with every holder and marketplace.</li>
                                        </ul>
                                    </div>
                                    <div className="chart-item box-03">
                                        <h3>REFLECTIONS</h3>
                                        <ul>
                                            <li>4% of every Transaction (Buy/Sell/Transfer) will be redistributed to all PIZZABUCKS HOLDERS.</li>
                                        </ul>
                                    </div>
                                    <div className="chart-item box-04">
                                        <h3>CHARITY</h3>
                                        <ul>
                                            <li>1% PIZZABUCKS and SAFEPIZZA is the same Team Admins, 1% of every transaction will be sent to SafePizza to directly support  the Mission for Charity. SAFEPIZZA CHARITY will donate to feeding the poor and needy. SAFEPIZZA will manage the Charity donations.</li>
                                        </ul>
                                    </div>
                                    <div className="chart-item box-03">
                                        <h3>MARKETING</h3>
                                        <ul>
                                            <li>2% NFT GAMING R/D - Majority of the Marketing budget will be set up to design and maintain the NFT Gaming and Rewards.</li>
                                            <li>2% Exchange Listing</li>
                                            <li>3% Marketing Campaigns and Promotions</li>
                                            <li>1% Team and Staff</li>
                                        </ul>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainImages
