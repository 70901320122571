import React from 'react'

const DocBox = () => {
    return (
        <div>
            <div className="col-md-10 col-md-offset-1">
                <div className="main-doc-box-container">
                    <a href="/litepaper">
                        <div className="doc-box">
                            <h3>LITEPAPER</h3>
                        </div>
                    </a>
                    {/* <a href="https://safe-pizza.com/whitepaper.pdf" target="_blank">
                        <div className="doc-box">
                            <h3>WHITEPAPER</h3>
                        </div>
                    </a> */}

                    <a href="#">
                        <div className="doc-box">
                            <h3>WHITEPAPER</h3>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default DocBox
