import React from 'react'

import Lottie from "lottie-react";
import PizzaBucksLottie from '../../assets/pizzabucklottie/animation.json'

const LottieImage = () => {
    return (
        <div className="lottie-main">
            <div className="lottie-sub">
                <div className="sub-image">
                    <Lottie animationData={PizzaBucksLottie}
                        onComplete
                    />
                </div>
            </div>
        </div>
    )
}

export default LottieImage
