import React from 'react'
import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'
import CharityRd from '../components/charityrd/CharityRd'

const Charity = () => {
    return (
        <>
            <SocialLinks />
            <NavBar />
            <CharityRd />
            <ScrollTop />
            <Footer />
        </>
    )
}

export default Charity
