import React, { useState } from 'react'

import Logo01 from '../../assets/newlogo/logo01.png'

const Buy = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0x4e34fcfc6a5aa091990b58f88490e67f25471b72')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <div className="starparalax">
            <div id="title" className="buy-now-class">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="token-container"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <div className="token-logo">
                                    <img src={Logo01} className="img-responsive" alt="" />
                                </div>
                                <h3>TOKEN INFO</h3>
                                <p>THE OFFICIAL PIZZABUCKS CONTRACT</p>
                                {/* <a href="https://bscscan.com/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6">0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6</a> */}
                                <div className="copy-buttons">
                                    {/* <h4>0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6</h4> */}
                                    <h4>0x4e34fcfc6a5aa091990b58f88490e67f25471b72</h4>
                                    <span>{alertmessage}</span> <button onClick={() => { copydata(); allertdata(); }} ><i class="far fa-copy"></i> COPY</button>
                                    <a target="_blank" href="https://bscscan.com/token/0x4e34fcfc6a5aa091990b58f88490e67f25471b72"><button>GO <i class="fas fa-chevron-right"></i></button></a>
                                </div>
                            </div>
                            <div className="main-logo-cont"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <div className="button-container how-to-buy">
                                    <a target="_blank" href="https://pancakeswap.finance/swap?outputCurrency=0x4E34FCFC6a5AA091990b58F88490E67f25471B72">PANCAKE SWAP V2 ONLY, USE 16% SLIPPAGE</a>
                                </div>
                            </div>
                            <div className="buy-instructions"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <h3>HOW TO BUY PIZZABUCKS TOKEN</h3>
                                <ul>
                                    <li>DOWNLOAD The TRUSTWALLET app.</li>
                                    <li>Set Up Your Trust Wallet and Write Down Your Seed Recovery Phrase. Keep in Very Safe Location. Never, Never Share This Phrase To Anyone or Fill It In Any Forms.</li>
                                    <li>Purchase BNB (then you must Dex BNB to Smart Chain)</li>
                                    <li>ONLY use BSC (Binance Smart Chain) To Buy in Pancake Swap V2</li>
                                    <li>Go to Dapps Tab on Lower Screen (for Android); Click on Pancake Swap icon</li>
                                    <li>For IPHONE – please WATCH this VIDEO Link <a href="https://www.youtube.com/watch?v=jtIiSF_z3vU">www.youtube.com/watch?v=jtIiSF_z3vU</a></li>
                                    <li>CLICK V2</li>
                                    <li>CONNECT Upper Right or UNLOCK Wallet (done once), Then Click on Trust Wallet</li>
                                    <li>SELECT CURRENCY from BNB (Smart Chain BNB) or USDT (if you already have that)</li>
                                    <li>To: Select Currency – Paste the PizzaBucks Contract to ADD. 0x4e34fcfc6a5aa091990b58f88490e67f25471b72</li>
                                    <li>ADD PizzaBucks to the Currency</li>
                                    <li>Now Select Slippage, Click on the Wheel (Cog) </li>
                                    <li>Type 18% and X to Close Slippage Pop Up</li>
                                    <li>Enter Amount of PizzaBucks You Want To Buy</li>
                                    <li>HIT SWAP</li>
                                    <li>CONFIRM</li>
                                    <li>Now Back to Main Trust Wallet Screen</li>
                                    <li>ADD Token – Upper Right Slide</li>
                                    <li>PASTE PizzaBucks Contract Address in SEARCH </li>
                                    <li>It Will Locate PizzaBucks and Now Slide Bar to Right So It Will Appear on Main Trust Wallet Summary of Token.</li>
                                    <li>HODL</li>
                                    <li>Or Buy More On Dips</li>
                                </ul>
                                <p>If you need Help, Go To <a href="https://t.me/pizzabucks">T.me/pizzabucks</a><br />
                                    Admin there will Assist.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Buy
