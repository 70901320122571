import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'

import Avatar01 from '../assets/avatars/avatar-01.png'
import Avatar02 from '../assets/avatars/avatar-02.png'
import Avatar03 from '../assets/avatars/avatar-03.png'
import Avatar04 from '../assets/avatars/avatar-04.png'
import Avatar05 from '../assets/avatars/avatar-05.png'




const Team = () => {
    const [showAvatar1, setShowAvatar1] = useState(false)

    return (
        <>
            <SocialLinks />
            <NavBar />
            <div className="container">
                <div className="team-main">
                    <div className="team-sub">
                        <div className="team-item">
                            <div className="lottieAvatar">
                                {!showAvatar1 ? <ReactPlayer
                                    playing={true}
                                    volume={"0"}
                                    onEnded={() => {
                                        setShowAvatar1(true)
                                    }}
                                    url='https://www.youtube.com/watch?v=S77Ie3UeXYU' /> : <img src={Avatar01} alt="" />}
                            </div>
                            <h3>KHANH</h3>
                            <h4>SafePizza CEO</h4>
                            <h5><a href="mailto:info@safe-pizza.com">info@safe-pizza.com</a></h5>
                            <a target="_blank" href="https://www.linkedin.com/in/safepizzaceo/" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                {!showAvatar1 ? <ReactPlayer
                                    playing={true}
                                    volume={"0"}
                                    onEnded={() => {
                                        setShowAvatar1(true)
                                    }}
                                    url='https://www.youtube.com/watch?v=Zt-Qzq85E-0' /> : <img src={Avatar02} alt="" />}
                            </div>
                            <h3>JAZY</h3>
                            <h4>SafePizza CTO</h4>
                            <h5><a href="mailto:admin@safe-pizza.com">admin@safe-pizza.com</a></h5>
                            <a target="_blank" href="https://www.linkedin.com/in/jazy-steven-louis-3948b5216/" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                {!showAvatar1 ? <ReactPlayer
                                    playing={true}
                                    volume={"0"}
                                    onEnded={() => {
                                        setShowAvatar1(true)
                                    }}
                                    url='https://www.youtube.com/watch?v=tIhEuVigaKU' /> : <img src={Avatar03} alt="" />}
                            </div>
                            <h3>CHARIS</h3>
                            <h4>SafePizza CFO</h4>
                            <h5><a href="mailto:cfo@safe-pizza.com">cfo@safe-pizza.com</a></h5>
                            <a target="_blank" href="https://www.linkedin.com/in/charis-zunaedi-9bba18216/" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                {!showAvatar1 ? <ReactPlayer
                                    playing={true}
                                    volume={"0"}
                                    onEnded={() => {
                                        setShowAvatar1(true)
                                    }}
                                    url='https://www.youtube.com/watch?v=NWLgiv6K9pM' /> : <img src={Avatar04} alt="" />}
                            </div>
                            <h3>DAVID</h3>
                            <h4>SafePizza CIO</h4>
                            <h5><a href="mailto:techsupport@safe-pizza.com">techsupport@safe-pizza.com</a></h5>
                            <a target="_blank" href="https://www.linkedin.com/in/david-m-569485216/" className="social-icon">
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </a>
                        </div>
                        <div className="team-item">
                            <div className="lottieAvatar">
                                {/* {!showAvatar1 ? <Lottie
                                    loop={false}
                                    onComplete={() => {
                                        setShowAvatar1(true)
                                    }}
                                    animationData={Admin} /> : <img src={Avatar05} alt="" />} */}
                                {!showAvatar1 ? <ReactPlayer
                                    playing={true}
                                    volume={"0"}
                                    onEnded={() => {
                                        setShowAvatar1(true)
                                    }}
                                    url='https://www.youtube.com/watch?v=bmdNF2P-Nis' /> : <img src={Avatar05} alt="" />}
                            </div>
                            <h3>NERO</h3>
                            <h4>SafePizza Group Admin</h4>
                            <h5><a href="mailto:groupadmin@safe-pizza.com">groupadmin@safe-pizza.com</a></h5>
                            <div target="_blank" href="#" className="social-icon" >
                                <span className="social-cell">
                                    <i class="fab fa-linkedin-in"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Team
