import React, { Fragment } from 'react'
import { Timeline, Event } from "react-timeline-scribble";

import Logo from '../../assets/logo.png'


import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


const TimeLine = () => {
    return (
        <div className="main-road-map" id="roadmap">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Fragment>
                            <h2>PIZZABUCKS ROADMAP</h2>

                            <VerticalTimeline>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#525556', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #525556' }}
                                    date="2021"
                                    iconStyle={{ background: '#34626C', color: '#fff' }}
                                // icon={<WorkIcon />}
                                >
                                    <h3 className="vertical-timeline-element-title">QUARTER 3</h3>
                                    <ul className="timeline-ui">
                                        <li>Inception: CIRCA:  AUGUST 9, 2021</li>
                                        <li>Token Name:  PIZZABUCKS</li>
                                        <li>Token Symbol: PIZZAB</li>
                                        <li>Total Supply: 200,000,000,000 (200 Billion)</li>
                                        <li>Decimal: 9</li>
                                        <li>Initial Burn: 50%</li>
                                        <li>Circulating Supply is 100,000,000,000 (100 Billion)</li>
                                        <li>Public Launch:  August 18, 2021</li>
                                        <li>Contract: 0x4e34fcfc6a5aa091990b58f88490e67f25471b72</li>
                                        <li>Platform:  BEP20 Binance Smart Chain</li>
                                        <li>LP Burned</li>
                                        <li>LP Locked: 50% Locked for 2yrs</li>
                                        <li>LP Locked: 50% for 3 months for Listing Purpose</li>
                                        <li>SafePizza Team will be PizzaBucks Team</li>
                                        <li>Ownership Doxed and Profile on Linkedin</li>
                                        <li>Dev Wallet Locked*</li>
                                        <li>Logo on PancakeSwap V2</li>
                                        <li>LitePaper Version1</li>
                                        <li>Set Up Media Links: </li>
                                        <ul>
                                            <li>Telegram: t.me/pizzabucks</li>
                                            <li>Telegram: t.me/pizzabucksannouncement</li>
                                            <li>Website:  www.Pizza-Bucks.com</li>
                                            <li>Facebook: www.facebook.com/pizzabuckscoin</li>
                                            <li>Instagram: www.Instagram.com/pizzabucksofficial</li>
                                            <li>Twitter: www.twitter.com/pizza_bucks</li>
                                            <li>TikTok: www.tiktok.com/pizzabucksofficial</li>
                                            <li>Discord: https://discord.com/invite/rBhxDscqnZ</li>
                                            <li>Reddit: www.reddit.com/r/PizzaBucksOfficial/?utm_medium=android_app&utm_source=share</li>
                                            <li>YouTube Channel: https://www.youtube.com/channel/UCzMCUnEpRVTRGBiUGO8marw</li>
                                            <li>Linkedin: https://www.linkedin.com/company/pizzabucks-official</li>
                                        </ul>
                                        <li>Promotions: YouTube Channel: Torin Hofmann Show</li>
                                        <li>Audit (TBD)</li>
                                        <li>CoinMarketCap Listing</li>
                                        <li>Trust Wallet Logo</li>
                                        <li>CoinGecko Listing</li>
                                        <li>WhitePaper – Available in Multiple of Languages</li>
                                        <li>Airdrop of PizzaBucks for Long Term Holders of 30 Days or More</li>
                                        <li>Website Update with All Languages Translation</li>
                                        <li>Slippage Reduced from 18% to 16%  (Aug 24, 2021)</li>
                                        <ul>
                                            <li>Breakdown:</li>
                                            <li>4% Reflections ( was 3% )</li>
                                            <li>3% Auto LP (was 2% )</li>
                                            <li>1% Charity</li>
                                            <li>8% Marketing and RD ( reduced )</li>
                                        </ul>
                                        <li>PizzaBucks Marketing Associate of Vietnam - Addition to The Team</li>
                                        <li>New Telegram Groups for International Cultures</li>
                                        <ul>
                                            <li>PizzaBucks Arabic t.me/PizzaBucksArabic</li>
                                            <li>PizzaBucks Argentina t.me/PizzaBucksArgentina</li>
                                            <li>PizzaBucks Brazil t.me/PizzaBucksBrazil</li>
                                            <li>PizzaBucks China t.me/PizzaBucksChina</li>
                                            <li>PizzaBucks Estonia t.me/PizzaBucksEstonia</li>
                                            <li>PizzaBucks France t.me/PizzaBucksFrance</li>
                                            <li>PizzaBucks Germany t.me/PizzaBucksGermany</li>
                                            <li>PizzaBucks India t.me/PizzaBucksIndia</li>
                                            <li>PizzaBucks Indonesia t.me/PizzaBucksIndonesia</li>
                                            <li>PizzaBucks Italy t.me/PizzaBucksItaly</li>
                                            <li>PizzaBucks Japan t.me/PizzaBucksJapan</li>
                                            <li>PizzaBucks Korea t.me/PizzaBucksKorea</li>
                                            <li>PizzaBucks Liechtenstein t.me/PizzaBucksLiechtenstein</li>
                                            <li>PizzaBucks Malta t.me/PizzaBucksMalta</li>
                                            <li>PizzaBucks Mexico t.me/PizzaBucksMexico</li>
                                            <li>PizzaBucks Monaco t.me/PizzaBucksMonaco</li>
                                            <li>PizzaBucks Netherlands t.me/PizzaBucksDutch</li>
                                            <li>PizzaBucks Nigeria t.me/PizzaBucksNigeria</li>
                                            <li>PizzaBucks Pakistan t.me/PizzaBucksPakistan</li>
                                            <li>PizzaBucks Peru t.me/PizzaBucksPeru</li>
                                            <li>PizzaBucks Philippines t.me/PizzaBucksFilipino</li>
                                            <li>PizzaBucks Portugal t.me/PizzaBucksPortugal</li>
                                            <li>PizzaBucks Russia t.me/PizzaBucksRussia</li>
                                            <li>PizzaBucks Singapore t.me/PizzaBucksSingapore</li>
                                            <li>PizzaBucks Spain t.me/PizzaBucksSpain</li>
                                            <li>PizzaBucks Sweden t.me/PizzaBucksSweden</li>
                                            <li>PizzaBucks Switzerland t.me/PizzaBucksSwitzerland</li>
                                            <li>PizzaBucks Thailand t.me/PizzaBucksThai</li>
                                            <li>PizzaBucks Turkey t.me/PizzaBucksTurkey</li>
                                            <li>PizzaBucks Ukraine t.me/PizzaBucksUkraine</li>
                                            <li>PizzaBucks Vietnam t.me/PizzaBucksVietnam</li>
                                        </ul>
                                        <li>Hiring / Recruiting Software Game Developers</li>
                                        <li>Designing Commercials and Ads for Social Media</li>
                                        <li>PizzaBucks Tracker</li>
                                        <li>SafePizza First Burns 445 Trillion</li>
                                        <li>SafePizza Charity Donation For Mumbai, India (first location)</li>
                                        <li>SafePizza Charity Donation For Vietnam</li>
                                        <li>SafePizza Charity Donation For Indonesia</li>
                                        <li>SafePizza Charity Donation For Mexico</li>
                                        <li>SafePizza Charity Donation For Ghana, Africa</li>
                                        <li>PizzaBucks Unitracker For All Tokens in Wallet</li>
                                        <li>Merchandise Shop Completed</li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#525556', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid #525556' }}
                                    date="2021"
                                    iconStyle={{ background: '#557174', color: '#fff' }}
                                // icon={<WorkIcon />}
                                >
                                    <h3 className="vertical-timeline-element-title">QUARTER 4</h3>
                                    <ul className="timeline-ui">
                                        <li>More TV Commercials Ads</li>
                                        <li>More Poocoin Ads</li>
                                        <li>More YouTube Influence Ads</li>
                                        <li>Times Square Ads</li>
                                        <li>NFT Gaming Design</li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#525556', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid #525556' }}
                                    date="2022"
                                    iconStyle={{ background: '#557174', color: '#fff' }}
                                // icon={<WorkIcon />}
                                >
                                    <h3 className="vertical-timeline-element-title">QUARTER 1</h3>
                                    <ul className="timeline-ui">
                                        <li>NFT Gaming Design</li>
                                        <li>SafePizza Burns</li>
                                        <li>SafePizza Charity Donation Starts</li>
                                    </ul>
                                </VerticalTimelineElement>
                            </VerticalTimeline>

                        </Fragment>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeLine
