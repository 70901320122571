import React from 'react'
import Contact from '../../assets/contact.png'

const ContactUs = () => {
    return (
        <>
            <div className="container" id="contactus">
                <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                        <h2 className="contact-header">Contact</h2>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contact-items"
                                    data-aos="fade-right"
                                    data-aos-delay="30"
                                    data-aos-duration="1500"
                                    data-aos-easing="ease-in-out-cubic"
                                >
                                    <a href="mailto:info@safe-pizza.com">info@safe-pizza.com</a>
                                    <a href="mailto:admin@safe-pizza.com">admin@safe-pizza.com</a>
                                    {/* <a href="mailto:cfo@safe-pizza.com">cfo@safe-pizza.com</a> */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contact-image"
                                    data-aos="fade-left"
                                    data-aos-delay="30"
                                    data-aos-duration="1500"
                                    data-aos-easing="ease-in-out-cubic"
                                >
                                    <img src={Contact} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs
