import React from 'react'
import LogoBuy from '../../assets/newlogo/logo01.png'


const Docs = () => {
    return (
        <div className="starparalax">
            <div id="title" className="buy-now-class">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="token-container"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <div className="token-logo doc-logo">
                                    <img src={LogoBuy} className="img-responsive" alt="" />
                                </div>
                            </div>
                            <div className="buy-instructions"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <h3>PizzaBucks Litepaper</h3>
                                <ul className="doc-list-ul">
                                    <li className="doc-list-li">
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Token Name
                                            </li>
                                            <li className="white-cont">
                                                PizzaBucks (PIZZAB)
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Token Type
                                            </li>
                                            <li className="white-cont">
                                                BEP-20 is a token standard on the Binance Smart Chain.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Contract creation date
                                            </li>
                                            <li className="white-cont">
                                                Aug-09-2021
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Token Supply
                                            </li>
                                            <li className="white-cont">
                                                Total supply: 200 Billion
                                            </li>
                                            <li className="white-cont">
                                                Circulation supply: 100 Billion
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Burn function
                                            </li>
                                            <li className="white-cont">
                                                50% Burn at launch.
                                            </li>
                                            <li className="white-cont">
                                                PIZZABUCKS will manually burn its supply on a monthly basis, as needed
                                            </li>
                                            <li className="white-cont">
                                                2% of every transaction (buy, sell or transfer) will be set aside for burn or buybacks for PIZZABUCKS AND SAFEPIZZA.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Mission
                                            </li>
                                            <li className="white-cont">
                                                Our mission is to build DEFI NFT Gaming Reward Games.  Our other mission is united with SafePizza’s Mission for Charity and Crypto Payment system.  Both PizzaBucks and SafePizza are one large ecosystem by design.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Vision
                                            </li>
                                            <li className="white-cont">
                                                Building Defi NFT Gaming on the BSC platform, providing NFT Gaming and Rewards to Gamers.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Fair Launch
                                            </li>
                                            <li className="white-cont">
                                                Founders and original developer wallets are locked for 6 months.
                                            </li>
                                            <li className="white-cont">
                                                Developers have made large and numerous investments into this project.
                                            </li>
                                            <li className="white-cont">
                                                We have a clear roadmap.
                                            </li>
                                            <li className="white-cont">
                                                The contract is verified and open source and accessible by anyone.
                                            </li>
                                            <li className="white-cont">
                                                Our team admins/investor members are present in our social media channels and their contact information is provided on our website and Linkedin.
                                            </li>
                                            <li className="white-cont">
                                                We are currently in the process of submitting an application to be audited.
                                            </li>
                                            <li className="white-cont">
                                                Fully dedicated team.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Token Ecosystem
                                            </li>
                                            <li className="white-cont">
                                                PizzaBucks token is based on the concept of Reflection, where every transaction is taxed. A percentage of the taxed amount is redistributed back to the holders. The longer you hold on to your token, the greater the reward.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                SafePizza and PizzaBucks
                                            </li>
                                            <li className="white-cont">
                                                SafePizza and PizzaBucks are a perfect pair portfolio.They are developed to complement one another. SafePizza had prior limitations that were originally setup by the prior founder. SafePizza offered a very pro investor approach of low slippage fees at 4%.  The issue of the slippage tax at 4% was that it didn't fully allow accelerated growth, did not allow for burning to create a deflationary supply, and it limited the expansion of SafePizza’s technology ecosystem.  PizzaBucks is launched now to maximize the growth potential for both SafePizza and PizzaBucks.  Both SafePizza and PizzaBucks are now a unified sharing force of Marketing power, to create more marketing dominance campaigns. The expansion of both SafePizza’s Ecosystem of Charity Base, Payment Base and PizzaBucks’ NFT Gaming Reward will provide a strong roadmap for current and long term investors to realize their greatest potential.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Pancake Swap (Version 2)
                                            </li>
                                            <li className="white-cont">
                                                This decentralized exchange permits investors to trade BEP-20 tokens. PancakeSwap relies on user-fueled liquidity pools to enable crypto trades. This token can be purchased on version two of Pancake swap. With SafePizza we were launched on V1 of Pancake swap, due to liquidity issues we were not able to migrate to V2. PizzaBucks will be launched on V2.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Slippage
                                            </li>
                                            <li className="white-cont">
                                                2% of every transaction (buy, sell or transfer) will be set aside for burn or buybacks for PIZZABUCKS AND SAFEPIZZA, to be determined as necessary.
                                            </li>
                                            <li className="white-cont">
                                                2% will be added to the liquidity pool.
                                            </li>
                                            <li className="white-cont">
                                                3% of every Transaction (Buy/Sell/Transfer) will be redistributed to all PIZZABUCKS HOLDERS.
                                            </li>
                                            <li className="white-cont">
                                                1% of every transaction will be sent to SafePizza, to directly support  the Mission for Charity. SAFEPIZZA CHARITY will donate to feeding the poor and needy. SAFEPIZZA will manage the Charity donations.
                                            </li>
                                            <li className="white-cont">
                                                3% NFT GAMING R/D -  Majority of the Marketing budget will be set up to design, develop and maintain the NFT Gaming and Rewards.
                                            </li>
                                            <li className="white-cont">
                                                2% will be for application and fees for the Exchange Listing process and LP deposits.
                                            </li>
                                            <li className="white-cont">
                                                4% will be for the Marketing Campaigns and Promotions.
                                            </li>
                                            <li className="white-cont">
                                                1% will be for Team and Staff running all the business activities, worldwide 24/7.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Team members
                                            </li>
                                            <li className="white-cont">
                                                This project was created by the leadership Team of SafePizza - CEO, Khanh, CTO, Jazy, CIO and David.
                                            </li>
                                        </ul>
                                        <ul className="doc-list-sub-ul">
                                            <li className="white-topic">
                                                Disclaimer
                                            </li>
                                            <li className="white-cont">
                                                The information in this Litepaper is for educational purposes only and is not investment or financial advice. It should not be relied upon for legal or investment recommendations. Please conduct your own research before making any investment decisions. Cryptocurrency investments are volatile and high risk in nature. Do not invest more than you can afford to lose. No regulatory authority has reviewed or granted approval for any information presented in this litepaper. The opinions reflected here are subject to change without notice.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Docs
