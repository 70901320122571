import React, { useState } from 'react'

import MarketinQr from '../../assets/donate/charity.jpg'
import LogoBuy from '../../assets/newlogo/logo01.png'


const CharityRd = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0x6eAC588745a1103395C8D6fb2F8Ada616E3be492')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <div className="starparalax">
            <div id="title" className="buy-now-class">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="token-logo doc-logo">
                                <img src={LogoBuy} className="img-responsive" alt="" />
                            </div>

                            <div className="token-container qr-image"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <h3>CHARITY</h3>
                                <h4>#FEED THE WORLD</h4>
                            </div>
                            <div className="main-logo-cont marketing-qr-container charity"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <div className="button-container qr-image">
                                    <div className="qr-wrapper">
                                        <h3>QR SMART CHAIN BNB</h3>
                                        <div className="button-img-container">
                                            <img src={MarketinQr} alt="" />
                                        </div>
                                        <p>CONTRACT ADDRESS FOR MARKETING FUND</p>
                                        <div className="copy-buttons qr-copy">
                                            <h4>0x6eAC588745a1103395C8D6fb2F8Ada616E3be492</h4>
                                            <button onClick={() => { copydata(); allertdata(); }} ><i class="far fa-copy"></i> COPY</button><span>{alertmessage}</span>
                                            <div className="charity-mail">
                                                <a href="mailto:charityadmin@safe-pizza.com"><i class="far fa-envelope"></i> charityadmin@safe-pizza.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="donation-plugin">
                                    <iframe
                                        src="https://nowpayments.io/embeds/donation-widget?api_key=HZJV828-TJBM19P-PFH10HV-J37ZSHP"
                                        width="354" height="680" frameborder="0"
                                        scrolling="no" >
                                        Can't load widget
                                    </iframe>
                                </div>
                            </div>
                            {/* <div className="buy-instructions qr-table"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <h3>Listing of Exchanges - Fees</h3>

                                <div className="qr-table">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Exchange</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><p>Bibox</p></td>
                                                <td><p>15-25K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>BigONE</p></td>
                                                <td><p>40-80K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>BiKi</p></td>
                                                <td><p>40-70K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Bilaxy</p></td>
                                                <td><p>15K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Bitcoin.com</p></td>
                                                <td><p>150-400K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>BitForex</p></td>
                                                <td><p>30-50K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Bithumb Global</p></td>
                                                <td><p>50-100K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>BitMart</p></td>
                                                <td><p>50-60K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Bittrex</p></td>
                                                <td><p>125-300K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>BKEX</p></td>
                                                <td><p>20-40K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>BW</p></td>
                                                <td><p>30K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>CatEx</p></td>
                                                <td><p>10K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>CoinBene</p></td>
                                                <td><p>90-150K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Coineal</p></td>
                                                <td><p>30-60K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>CoinEx</p></td>
                                                <td><p>40-100K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Coinsbit</p></td>
                                                <td><p>20-40K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>CoinTiger</p></td>
                                                <td><p>30-50K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Dcoin</p></td>
                                                <td><p>15K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>DigiFinex</p></td>
                                                <td><p>30-40K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Emirex</p></td>
                                                <td><p>6.5-15K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>ExMarkets</p></td>
                                                <td><p>5-16K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>EXMO</p></td>
                                                <td><p>45-100K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>GoPax</p></td>
                                                <td><p>60-90K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>HitBTC</p></td>
                                                <td><p>55-65K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Hoo</p></td>
                                                <td><p>30-40K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Hotbit</p></td>
                                                <td><p>30-50K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>KuCoin</p></td>
                                                <td><p>150-250K$ listing fee. 50K$ marketing budget in tokens. MM deposit 100-300K$ (50/50 cash token (USDT, USDC, BTC, ETH) and project token)</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>LATOKEN</p></td>
                                                <td><p>15-50K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>LBank</p></td>
                                                <td><p>30-60K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>MXC</p></td>
                                                <td><p>60-180K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>OKEx</p></td>
                                                <td><p>800K-1M$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>P2PB2B</p></td>
                                                <td><p>10K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>ProBit</p></td>
                                                <td><p>25-50K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>WhiteBIT</p></td>
                                                <td><p>50-80K$</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>XT</p></td>
                                                <td><p>20-70K$</p></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div className="marketing-ul-container">
                                        <ul className="qr-image-ul">
                                            <li>Audit fees $1800-2000</li>
                                            <li>Graphics and Animation : $8000 month</li>
                                            <li>WebDesign Update $200 month</li>
                                            <li>Promotions / YouTube Influencers $10-15K Q3, $15k-30K Q4</li>
                                            <li>RD Developer Charts and Payment App $25K* minimum Q3-Q4</li>
                                            <li>Advertising and Ads $10K Q3</li>
                                            <li>TechSupport Team $8K-10K a month Q3</li>
                                            <li>Merchandise / Shop Set up and Inventory: $15K Q3</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CharityRd
