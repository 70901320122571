import React from 'react'
import SocialLinks from '../components/sociallinks/SocialLinks'
import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'
import Docs from '../components/docs/Docs'

const LightPaper = () => {
    return (
        <>
            <SocialLinks />
            <NavBar />
            <div className="container">
                <div className="comming-soon">
                    {/* <h1>NEW VERSION OF WHITEPAPER SOON TO BE RELEASED.</h1> */}
                    <Docs />
                </div>
            </div>
            <Footer />

        </>
    )
}

export default LightPaper
