import React from 'react'
import LogoBuy from '../../assets/newlogo/logo01.png'

import Faq from 'react-faq-component';

const data = {
    title: "",
    rows: [
        {
            title: "1. What’s your official website?",
            content: "<h4><a href='https://www.pizza-bucks.com' target='_blank'>www.pizza-bucks.com</a> </4>"
        },
        {
            title: "2. Where can I go for updates?",
            content: "<h4>Check out our announcement group in Telegram: <a href='https://t.me@PizzaBucksAnnouncement' target='_blank'>t.me@PizzaBucksAnnouncement</a></h4> <h4>Community / 24-7 Support: Telegram: <a href='https://t.me@pizzabucks' target='_blank'>t.me@pizzabucks</a></h4>   "
        },
        {
            title: "3. Do you have an Instagram page?",
            content: "<h4><a href='https://www.instagram.com/pizzabucksofficial/' target='_blank'>Instagram</a> </4>"
        },
        {
            title: "4. Can I find your team on LinkedIn?",
            content: "<h4>Yes, you’ll find frequent updates here. LinkedIn <a href='https://www.linkedin.com/company/pizzabucks-official' target='_blank'>www.linkedin.com/company/pizzabucks-official/</a> </4>"
        },
        {
            title: "5. Are you on Twitter?",
            content: "<h4>Twitter: <a href='https://twitter.com/pizza_bucks' target='_blank'>Twitter.com/pizza_bucks</a> </4>"
        },
        {
            title: "6. What’s your Facebook page?",
            content: "<h4>Facebook: <a href='https://www.facebook.com/pizzabuckscoin' target='_blank'>www.facebook.com/pizzabuckscoin</a> </4>"
        },
        {
            title: "7. Is there a link for me to access the Discord Channel?",
            content: "<h4>Discord: <a href='https://discord.com/invite/rBhxDscqnZ' target='_blank'>discord.com/invite/rBhxDscqnZ</a> </4>"
        },
        {
            title: "8. Are you on Reddit?",
            content: "<h4>Reddit: <a href='https://www.reddit.com/r/pizzabucksofficial' target='_blank'>www.reddit.com/r/pizzabucksofficial/</a> </4>"
        },
        {
            title: "9. Do you have a YouTube channel?",
            content: "<h4>YouTube: <a href='https://www.youtube.com/channel/UCzMCUnEpRVTRGBiUGO8marw' target='_blank'>www.youtube.com/channel/UCzMCUnEpRVTRGBiUGO8marw</a> </4>"
        },
        {
            title: "10. Can I find you guys on Coin Gecko and Coinmarketcap?",
            content: "<h4>Coingecko <a href='https://www.coingecko.com/en/coins/pizzabucks' target='_blank'>www.coingecko.com/en/coins/pizzabucks</a> </4><h4>Coinmarketcap <a href='http://coinmarketcap.com/currencies/pizzabucks/' target='_blank'>coinmarketcap.com/currencies/pizzabucks/</a> </4>"
        },
        {
            title: "11. When did this token first launch?",
            content: "<h4>Monday, August 9, 2021</4>"
        },
        {
            title: "12. What’s the symbol for this token?",
            content: "<h4>PIZZAB</4>"
        },
        {
            title: "13. Does your team have a Roadmap?",
            content: "<h4>Roadmap <a href='https://pizza-bucks.com/#roadmap' target='_blank'>https://pizza-bucks.com/#roadmap</a> </4>"
        },
        {
            title: "14. Where can I find some chart data? Poocoin and Dex.guru",
            content: "<h4>Poocoin <a href='https://poocoin.app/tokens/0x4e34fcfc6a5aa091990b58f88490e67f25471b72' target='_blank'>https://poocoin.app/tokens/0x4e34fcfc6a5aa091990b58f88490e67f25471b72</a> </4><h4>Dex.guru <a href='https://dex.guru/token/0x4e34fcfc6a5aa091990b58f88490e67f25471b72-bsc' target='_blank'>https://dex.guru/token/0x4e34fcfc6a5aa091990b58f88490e67f25471b72-bsc</a> </4>"
        },
        {
            title: "15. How do I know that this is not a rug pull?",
            content: "<h4>Founders and original developers wallets are Locked for 6 months.</h4><h4>Developers have made large and numerous investments into this project.</h4><h4>We have a clear roadmap.</h4><h4>The contract is verified and open source and accessible by anyone?</h4><h4>Our team admins/investor members are present in our social media channels and their contact information is provided on our website and Linkedin.</h4><h4>We are currently in the process of submitting an application to be audited.</h4><h4>Fully dedicated team.</h4>"
        },
        {
            title: "16. What is your contract Address?",
            content: "<h4>0x4e34fcfc6a5aa091990b58f88490e67f25471b72 Link: <a href='https://bscscan.com/token/0x4e34fcfc6a5aa091990b58f88490e67f25471b72' target='_blank'>https://bscscan.com/token/0x4e34fcfc6a5aa091990b58f88490e67f25471b72</a> </4>"
        },
        {
            title: "17. Who owns the contract?",
            content: "<h4>Ownership is the same SafePizza Admin Team.  They are on the website and Linkedin. The developers of this project burned all 100% liquidity pool tokens.</4>"
        },
        {
            title: "18. Where can I find your contract?",
            content: "<h4>Contract <a href='https://bscscan.com/token/0x4e34fcfc6a5aa091990b58f88490e67f25471b72' target='_blank'>https://bscscan.com/token/0x4e34fcfc6a5aa091990b58f88490e67f25471b72</a> </4>"
        },
        {
            title: "19. What’s the total token supply:",
            content: "<h4>200,000,000,000 (200 billion)</4>"
        },
        {
            title: "20. Do I have to pay a tax for each transaction?",
            content: "<h4>There is a 18% tax/slippage on all transactions. Tokenomics Tax is 18%</4> <h4>I. BURN</h4><h4>PIZZABUCKS will periodically Manually burn its supply on a monthly basis, as needed. <br> 2% of every transaction (buy, sell or transfer) will be set aside for burn or buybacks for PIZZABUCKS AND SAFEPIZZA <br>PIZZABUCKS will weekly buy SafePizza to send to Burn Address to keep reducing the supply of SafePizza. Each buy of SafePizza will provide reflection for SafePizza Holders and Auto LP’s, while the process is <br> sent to Burn Address. <br></h4> <h4>II. LIQUIDITY</h4><h4>2% Auto Liquidity is set up for PIZZABUCKS.  Each Transaction of Buy/Sell/Trade will be sent to Liquidity, to ensure that the Liquidity is kept in a healthy ratio with every holder and marketplace. <br></h4><h4>III. REFLECTIONS</h4><h4>3% of every Transaction (Buy/Sell/Transfer) will be redistributed to all PIZZABUCKS HOLDERS.<br></h4><h4>IV. CHARITY</h4><h4>PIZZABUCKS and SAFEPIZZA is the same Team Admins, 1% of every transaction will be sent to SafePizza to directly support  the Mission for Charity. SAFEPIZZA CHARITY will donate to feeding the poor and needy. SAFEPIZZA will manage the Charity donations.<br></h4><h4>V. MARKETING</h4><h4>3% NFT GAMING R/D -  Majority of the Marketing budget will be set up to design and maintain the NFT Gaming and Rewards.<br>2% Exchange Listing <br> 4% Marketing Campaigns and Promotions <br> 1% Team and Staff <br></h4>"
        },
        {
            title: "21. How many free tokens can I expect?",
            content: "<h4>There’s no exact amount, but you receive free tokens by holding and buying.</4>"
        },
        {
            title: "22. Which version of pancake swap should I use to purchase this coin?",
            content: "<h4>Pancake Swap V2.</4>"
        },
        {
            title: "23. Why is there a price difference between the two versions of Pancake Swap?",
            content: "<h4><a href='https://pizza-bucks.com/howtobuy' target='_blank'>pizza-bucks.com/howtobuy</a></4>"
        },
        {
            title: "24. How can I buy PIZZABUCKS on Pancake Swap V2?",
            content: "<h4>How to buy <a href='https://pizza-bucks.com/howtobuy' target='_blank'>https://pizza-bucks.com/howtobuy</a> </4>"
        },
        {
            title: "25. What's the direct address to purchase this coin on Pancake swap V2.",
            content: "<h4>Pancakeswap V2 <a href='https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4e34fcfc6a5aa091990b58f88490e67f25471b72' target='_blank'>https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4e34fcfc6a5aa091990b58f88490e67f25471b72</a> </4>"
        },
        {
            title: "26. What Is Slippage?",
            content: "<h4>The slippage for PizzaBucks is 18%. <br>Preventing Whales Pump and Dump<br> <a href='https://coinmarketcap.com/alexandria/glossary/slippage' target='_blank'>https://coinmarketcap.com/alexandria/glossary/slippage</a> </4>"
        },
        {
            title: "27. Price Prediction?",
            content: "<h4>The longer you hold a token the greater the reward.</4>"
        },
        {
            title: "28. Have you gone through an audit?",
            content: "<h4>We are in the process of applying for an audit.</4>"
        },
        {
            title: "29. Where can we buy merchandise:",
            content: "<h4>ETA end of July. Shopping on our site: https://www.shop.pizza-bucks.com/</4>"
        },
    ]
}


const FaqItems = () => {



    return (
        <div className="starparalax">
            <div id="title" className="buy-now-class">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="token-container"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <div className="token-logo">
                                    <img src={LogoBuy} className="img-responsive" alt="" />
                                </div>
                            </div>
                            <div className="buy-instructions"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out-cubic"
                            >
                                <h3>FAQ</h3>
                                <div className="faq-items">
                                    <Faq data={data}
                                        styles={{
                                            titleTextColor: "#ededed",
                                            rowTitleColor: "#ededed",
                                            rowContentColor: "#fff"
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqItems
